

$(function () {

	// const setFillHeight = () => {
	// 	const vh = window.innerHeight * 0.01;
	// 	document.documentElement.style.setProperty('--vh', `${vh}px`);
	// }

	// // 画面のサイズ変動があった時に高さを再計算する
	// window.addEventListener('resize', setFillHeight);

	// // 初期化
	// setFillHeight();


	$('.container').on('click', function () {
		$('.headerNavi').removeClass('show');
		$('#menuBtn').removeClass('on');
	});

	// $('.pagination a').on('click', function () {
	// 	$('.container').addClass('scrolling');
	// });

	// // モーダルウィンドウを開く
	// $('.modalBtn').on('click', function () {
	// 	var target = $(this).data('target');
	// 	var modal = document.getElementById(target);
	// 	$(modal).addClass("open");
	// 	console.log("open");
	// 	return false;
	// });

	// //モーダルウィンドウを閉じる
	// $('.movieArea__close,.movieArea').on('click', function () {
	// 	console.log("close");
	// 	$('.movieArea').removeClass("open");
	// 	return false;
	// });





	$.scrollify({
		section: '.scSection',
		easing: 'swing',
		scrollSpeed: 600,
		updateHash: false,
		standardScrollElements: '.nosc',
		setHeights: false,

	});

});

new ModalVideo('.js_modalBtn-vimeo', {
	channel: 'vimeo', vimeo: {
		title: false,

	},
});
new ModalVideo('.js_modalBtn-youtube');
